import { action, computed, observable } from 'mobx';
import { AccelFile, Entity, FlowDate, PaymentGateway, ProductContent, Scenario } from '..';
import { isEmpty } from '../../utils';
import { ProductPaymentGatewaySettings } from './ProductPaymentGatewaySettings/ProductPaymentGatewaySettings';
import { ProductType } from './ProductType';
import moment from 'moment';

export enum ProductAccessTimeType {
    Endless = 'endless',
    Duration = 'duration',
    Period = 'period'
}

export enum ProductAccessDurationType {
    Day = 'day',
    Month = 'month'
}

export enum ProductBonusPaymentType {
    Percent = 'percent',
    Value = 'value'
}

export enum ProductLifetimeLimitType {
    Duration = 'duration',
    Date = 'date'
}

export default class Product extends Entity {
    constructor(product?: Partial<Product>) {
        super(product);
        if (product) this.update(product);
    }

    @observable name: string;
    @observable internalName: string;
    @observable description: string;
    @observable createdDate: moment.Moment;
    @observable updatedDate: moment.Moment;
    @observable orderCount: number;
    @observable contactCount: number;
    @observable isPublished: boolean;

    @observable type: ProductType;

    @observable preventSystemEmail: boolean;
    @observable files: AccelFile[];
    @observable image: AccelFile | null;
    @observable scenario: Scenario;
    @observable flowDate: FlowDate;

    @observable contents: ProductContent[];

    //#region Not subsciption fields

    @observable oneTimePrice: number | null;
    @observable oneTimeDisplayPrice: number | null;
    @observable useOneTimeDisplayPrice: boolean;

    @observable useDonationPayment: boolean;
    @observable minDonationPaymentAmount: number;

    @observable duration: number | null;
    @observable durationType: ProductAccessDurationType | null;

    @observable beginDate: moment.Moment | null;
    @observable endDate: moment.Moment | null;

    @observable useBonusPayment: boolean;
    @observable bonusPaymentAmount: number;
    @observable bonusPaymentType: ProductBonusPaymentType;

    @observable limitOrderLifetime: boolean = false;
    @observable limitOrderLifetimeMode: ProductLifetimeLimitType = ProductLifetimeLimitType.Duration;
    @observable limitOrderLifetimeDuration: number | null = null;
    @observable limitOrderLifetimeDate: moment.Moment | null = null;

    @computed get accessTimeType(): ProductAccessTimeType | null {
        if (this.duration == null && !this.beginDate && !this.endDate)
            return ProductAccessTimeType.Endless;
        if (this.duration != null)
            return ProductAccessTimeType.Duration;
        return ProductAccessTimeType.Period;
    }

    @computed get hasInternalName() {
        return !isEmpty(this.internalName);
    }

    @observable freeDownload: boolean;
    @observable installmentDisabled: boolean;
    //#endregion


    //#region Subscription fields

    @observable subsPrice: number | null;
    @observable subsDuration: number | null;
    @observable subsDurationType: ProductAccessDurationType | null;
    @observable subsRenewalCreditDayCount: number | null;
    subsRenewalDefaultCreditDayCount: number;

    @observable useTrialSubs: boolean;
    @observable trialSubsPrice: number | null;
    @observable trialSubsDuration: number | null;
    @observable trialSubsDurationType: ProductAccessDurationType | null;

    @computed get subsAccessTimeType(): ProductAccessTimeType | null {
        if (this.duration == null && !this.beginDate && !this.endDate)
            return ProductAccessTimeType.Endless;
        if (this.duration != null)
            return ProductAccessTimeType.Duration;
        return ProductAccessTimeType.Period;
    }

    //#endregion

    //#region Payment gateways
    @observable paymentGateways: PaymentGateway[];
    /**
     * rename to paymentGatewayOverrides
     */
    @observable paymentGatewaySettings: ProductPaymentGatewaySettings[];
    @observable relatedProducts: Product[];
    @action addPaymentGatewaySettings(settings: ProductPaymentGatewaySettings) {
        this.paymentGatewaySettings.push(settings);
    }
    @action removePaymentGatewaySettings(settings: ProductPaymentGatewaySettings) {
        this.paymentGatewaySettings.remove(settings);
    }
    //#endregion

    @computed get price() {
        if (this.type == ProductType.Subscription)
            return this.subsPrice ?? 0;
        return this.oneTimePrice ?? 0;
    }

    @action update(product: Partial<Product>, allowUndefined = false) {
        super.update(product, allowUndefined);
    }

    static fromJson(json: any): Product {
        const product = new Product({
            id: json.id,
            name: json.name,
            internalName: json.internalName,
            description: json.description,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            orderCount: json.orderCount,
            contactCount: json.contactCount ?? 0,
            type: json.type,
            preventSystemEmail: json.preventSystemEmail,
            files: json.fileLinks ? json.fileLinks.map((x: any) => AccelFile.fromJson(x.file)) : [],
            image: json.image ? AccelFile.fromJson(json.image) : undefined,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined,
            contents: json.contents ? json.contents.map(ProductContent.fromJson) : [],
            isPublished: json.isPublished,

            oneTimePrice: json.oneTimePrice ?? null,
            oneTimeDisplayPrice: json.oneTimeDisplayPrice ?? null,
            useOneTimeDisplayPrice: json.useOneTimeDisplayPrice,
            duration: json.duration ?? null,
            durationType: json.durationType ?? null,
            beginDate: json.beginDate ? moment(json.beginDate) : null,
            endDate: json.endDate ? moment(json.endDate) : null,

            freeDownload: json.freeDownload,
            installmentDisabled: json.installmentDisabled,

            subsPrice: json.subsPrice ?? null,
            subsDuration: json.subsDuration ?? null,
            subsDurationType: json.subsDurationType ?? null,
            subsRenewalCreditDayCount: json.subsRenewalCreditDayCount ?? null,
            subsRenewalDefaultCreditDayCount: json.subsRenewalDefaultCreditDayCount,
            useTrialSubs: json.useTrialSubs ?? false,
            trialSubsPrice: json.trialSubsPrice ?? false,
            trialSubsDuration: json.trialSubsDuration ?? false,
            trialSubsDurationType: json.trialSubsDurationType ?? null,

            useBonusPayment: json.useBonusPayment ?? false,
            bonusPaymentAmount: json.bonusPaymentAmount ?? 0,
            bonusPaymentType: json.bonusPaymentType ?? ProductBonusPaymentType.Percent,

            useDonationPayment: json.useDonationPayment ?? false,
            minDonationPaymentAmount: json.minDonationPaymentAmount ?? 0,

            flowDate: json.flowDate ? FlowDate.fromJson(json.flowDate) : undefined,
            softDeleted: json.softDeleted,
            paymentGatewaySettings: json.paymentGatewaySettings ? json.paymentGatewaySettings.map(ProductPaymentGatewaySettings.fromJson) : [],
            paymentGateways: json.paymentGatewayLinks ? json.paymentGatewayLinks.map((x: any) => PaymentGateway.fromJson(x.gateway)) : [],
            relatedProducts: json.relatedProductLinks ? json.relatedProductLinks.map((x: any) => Product.fromJson(x.target)) : [],
            limitOrderLifetime: json.limitOrderLifetime,
            limitOrderLifetimeMode: json.limitOrderLifetimeMode,
            limitOrderLifetimeDuration: json.limitOrderLifetimeDuration,
            limitOrderLifetimeDate: json.limitOrderLifetimeDate ? moment(json.limitOrderLifetimeDate) : null
        });

        if (product.scenario)
            product.scenario.update({ product });

        return product;
    }

    clone(changes?: Partial<Product>): Product {
        return new Product({
            ...this,
            files: this.files.slice(),
            contents: this.contents.slice(),
            paymentGatewaySettings: this.paymentGatewaySettings.slice(),
            paymentGateways: this.paymentGateways.slice(),
            relatedProducts: this.relatedProducts.slice(),
            ...changes
        });
    }

    hasChanges(product: Product) {
        return this.name != product.name
            || this.internalName != product.internalName
            || this.description != product.description
            || this.oneTimePrice != product.oneTimePrice
            || this.oneTimeDisplayPrice != product.oneTimeDisplayPrice
            || this.useOneTimeDisplayPrice != product.useOneTimeDisplayPrice
            || this.useDonationPayment != product.useDonationPayment
            || this.minDonationPaymentAmount != product.minDonationPaymentAmount
            || this.freeDownload != product.freeDownload
            || this.installmentDisabled != product.installmentDisabled
            || this.preventSystemEmail != product.preventSystemEmail
            || this.type != product.type
            || !this.files.isEquals(product.files, (a, b) => a.id == b.id)
            || this.type != product.type
            || !this.contents.isEquals(product.contents, (a, b) => !a.hasChanges(b))
            || this.duration != product.duration
            || this.durationType != product.durationType
            || this.beginDate != product.beginDate
            || this.endDate != product.endDate
            || this.useTrialSubs != product.useTrialSubs
            || this.subsDurationType != product.subsDurationType
            || this.subsDuration != product.subsDuration
            || this.subsPrice != product.subsPrice
            || this.subsRenewalCreditDayCount != product.subsRenewalCreditDayCount
            || this.trialSubsDurationType != product.trialSubsDurationType
            || this.trialSubsDuration != product.trialSubsDuration
            || this.trialSubsPrice != product.trialSubsPrice
            || this.flowDate?.id != product.flowDate?.id
            || this.image?.id != product.image?.id
            || !this.paymentGatewaySettings.isEquals(product.paymentGatewaySettings, (a, b) => !a.hasChanges(b))
            || !this.paymentGateways.isEquals(product.paymentGateways, (a, b) => a.id == b.id)
            || this.useBonusPayment != product.useBonusPayment
            || this.bonusPaymentAmount != product.bonusPaymentAmount
            || this.bonusPaymentType != product.bonusPaymentType
            || !this.relatedProducts.isEquals(product.relatedProducts, (a, b) => a.id == b.id)
            || this.limitOrderLifetime != product.limitOrderLifetime
            || this.limitOrderLifetimeMode != product.limitOrderLifetimeMode
            || this.limitOrderLifetimeDuration != product.limitOrderLifetimeDuration
            || this.limitOrderLifetimeDate != product.limitOrderLifetimeDate;
    }
}