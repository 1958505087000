import { observable, action } from 'mobx';
import moment from 'moment';
import { School, AccelFile, User } from '..';

export enum AdminType {
    Simple = 'simple',
    Api = 'api'
}

export enum AdminPartnerStatus {
    NotPartner = 'notPartner',
    Silver = 'silver',
    Gold = 'gold',
    Platinum = 'platinum'
}

export default class Admin extends User {
    constructor(user?: Partial<Admin>) {
        super(user);
        if (user) this.update(user);
    }

    @observable post: string | null;
    @observable isEmailConfirmed: boolean;
    @observable emailConfirmationSentDate: Date | null;
    @observable isPhoneConfirmed: boolean;
    @observable needChangePassword: boolean;
    /**
     * current selected school
     */
    @observable school: School | null;
    /**
     * schools you own
     */
    @observable schools: School[];
    @observable partner: Admin | null;
    /**
     * Source type
     *  - api key
     *  - simple admin
     */
    @observable type: AdminType;
    @observable partnerStatus: AdminPartnerStatus;
    @observable createdDate: moment.Moment;

    clone(): Admin{
        return new Admin({
            ...this,
            school: this.school?.clone(),
            schools: this.schools.map(s => s.clone()),
            partner: this.partner?.clone(),
            avatar: this.avatar?.clone(),
            birthday: this.birthday ? new Date(this.birthday) : undefined,
            createdDate: this.createdDate ? moment(this.createdDate) : undefined,
            emailConfirmationSentDate: this.emailConfirmationSentDate ? new Date(this.emailConfirmationSentDate) : null,
        });
    }

    @action update(user: Partial<Admin>, allowUndefined = false) {
        super.update(user, allowUndefined);
    }

    static fromJson(json: any): Admin {
        return new Admin({
            ...json,
            school: json.school ? School.fromJson(json.school) : null,
            schools: json.schools ? json.schools.map(School.fromJson) : [],
            partner: json.partner ? Admin.fromJson(json.partner) : null,
            avatar: json.avatar ? AccelFile.fromJson(json.avatar) : undefined,
            birthday: json.birthday ? new Date(json.birthday) : undefined,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            emailConfirmationSentDate: json.emailConfirmationSentDate ? new Date(json.emailConfirmationSentDate) : null,
        });
    }
}