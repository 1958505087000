import { ApiRequest, ApiResponse, HttpMethod } from '../../..';

export class MoveFileManagerItemsRequest extends ApiRequest {
	public method = HttpMethod.POST;
	public url: string = `/file-manager/move`;

	public body = {
		ids: this.ids,
		parentId: this.parentId
	}
	constructor(public ids: string[], public parentId: string | null) {
		super();
	}
}

export class MoveFileManagerItemsResponse extends ApiResponse {
	ids: string[];
	constructor(response: any) {
		super(response);
		this.ids = this.body;
	}
}
