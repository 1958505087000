
import { observable, action, computed } from 'mobx';
import { CallAccount, CallRecord, Entity, School, CallUser, CallAccountType, CallSIPRegistration } from '../..';
import { Currency } from '../../../enums';

export type CallSessionState = 'inProgress'
    | 'completed'
    | 'failed'
    | 'waitingForCompletion'
    | 'answered'
    | 'rejected'
    | 'canceled'
    | 'busy'
    | 'timeExpired'
    | 'disabled'
    | 'unprocessedType';

export default class CallSession extends Entity {
    constructor(callSession?: Partial<CallSession>) {
        super(callSession);
        if (callSession) this.update(callSession);
    }

    @observable callAccount: CallAccount;
    @observable fromUser: CallUser;
    @observable toUser: CallUser;
    @observable records: CallRecord[];
    @observable school: School;
    @observable sipRegistration: CallSIPRegistration | null;

    @observable durationInSec: number;

    @observable providerCallCost: number;
    @observable providerRecordCost: number;
    @observable providerResourseCost: number;
    @observable totalProviderCost: number;
    @observable currency: Currency;

    @observable state: CallSessionState;
    @observable isIncoming: boolean;

    @observable startDate?: Date;
    @observable createdDate?: Date;
    @observable updatedDate?: Date;
    @observable completedDate?: Date;

    @computed get hasCost() {
        if (!this.callAccount) return false;
        return this.callAccount.type !== CallAccountType.OnlinePbx;
    }

    @computed get isSuccessful() {
        return this.state === 'completed';
    }
    
    static fromJson(json: any): CallSession {
        return new CallSession({
            ...json,
            callAccount: json.callAccount ? CallAccount.fromJson(json.callAccount) : undefined,
            fromUser: json.fromUser ? CallUser.fromJson(json.fromUser) : undefined,
            toUser: json.toUser ? CallUser.fromJson(json.toUser) : undefined,
            records: json.records ? json.records.map(CallRecord.fromJson) : undefined,
            school: json.school ? School.fromJson(json.school) : undefined,
            sipRegistration: json.sipRegistration ? CallSIPRegistration.fromJson(json.sipRegistration) : null,
            startDate: json.startDate ? new Date(json.startDate) : undefined,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            completedDate: json.completedDate ? new Date(json.completedDate) : undefined,
        });
    }

    @action
    update(callSession: Partial<CallSession>) {
        super.update(callSession);
    }
}

