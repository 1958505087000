import { action, computed } from 'mobx';
import moment, { type Moment } from 'moment';
import { Entity } from '../..';

export default class BillingPromoCode extends Entity {
    constructor(code?: Partial<BillingPromoCode>) {
        super(code);
        if (code) this.update(code);
    }

    name: string;
    code: string;
    description: string;
    bonusBalance?: number;
    trialDaysCount?: number;
    beginDate: Moment | null;
    endDate: Moment | null;
    createdDate: Moment;
    updatedDate?: Moment;

    @computed get endless() {
        return !this.beginDate && !this.endDate;
    }

    clone() {
        return new BillingPromoCode({
            ...this
        });
    }

    static fromJson(json: any): BillingPromoCode {
        return new BillingPromoCode({
            id: json.id,
            name: json.name,
            code: json.code,
            description: json.description,
            bonusBalance: json.bonusBalance,
            trialDaysCount: json.trialDaysCount,
            beginDate: json.beginDate ? moment(json.beginDate) : null,
            endDate: json.endDate ? moment(json.endDate) : null,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
        });
    }

    @action
    update(t: Partial<BillingPromoCode>) {
        super.update(t);
    }
}

