import { observable, action } from 'mobx';
import { Entity, AccelFile, VideoStream, VideoAccess } from '..';
import { Lang } from '../../enums';

export type VideoBreadcrumbItem = {
    id: string;
    parentId: string;
    name: string;
    softDeleted: boolean;
}

export default class Video extends Entity {
    constructor(video?: Partial<Video>) {
        super(video);
        if (video) this.update(video);
    }

    @observable name: string;
    @observable shortDescription: string;
    @observable lang: Lang;
    @observable viewsCount: number;

    @observable createdDate: Date;
    @observable updatedDate: Date;
    @observable isNotCompletedStreamExist: boolean;

    @observable currentVideoStream: VideoStream;
    @observable primaryImage: AccelFile;
    @observable primaryImageFileId: string;

    @observable accessAnywhere: boolean;
    @observable access: VideoAccess | null;

    @observable breadcrumbs: VideoBreadcrumbItem[];

    static fromJson(json: any): Video {
        return new Video({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            currentVideoStream: json.currentVideoStream ? VideoStream.fromJson(json.currentVideoStream) : undefined,
            primaryImage: json.primaryImage?.file ? AccelFile.fromJson(json.primaryImage.file) : undefined,
            primaryImageFileId: json.primaryImage?.file?.id ? json.primaryImage.file.id : undefined,
            access: json.videoAccess ? VideoAccess.fromJson(json.videoAccess) : null,
            breadcrumbs: json.path,
        });
    }

    @action
    update(changes: Partial<Video>, allowUndefined = false) {
        super.update(changes, allowUndefined);
    }

    copy(): Video {
        return Video.fromJson(JSON.parse(JSON.stringify(this)))
    }
}

