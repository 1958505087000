import { action } from 'mobx';
import { CustomField, CustomFieldType } from '../..';

export default class CustomAttribute extends CustomField {
    constructor(attr?: Partial<CustomAttribute>) {
        super(attr);
        if (attr) this.update(attr);
    }

    @action update(attr: Partial<CustomAttribute>, allowUndefined = false) {
        super.update(attr, allowUndefined);
    }

    clone(): CustomAttribute {
        return new CustomAttribute({
            ...this,
            type: this.type ?? CustomFieldType.Text,
            additionalData: this?.additionalData ?? JSON.stringify([])
        });
    }

    static default(){
        return new CustomAttribute({
            type: CustomFieldType.Text,
            additionalData: JSON.stringify([])
        });
    }

    static fromJson(json: any) {
        return new CustomAttribute({
            ...json
        });
    }
}