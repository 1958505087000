import React from "react";

type Props = {
    footer: React.ReactNode;
}

const SelectFooter: React.FC<Props> = ({ footer, children }) => {
    return <>
        {children}
        <div className="border-t pt-4">
            {footer}
        </div>
    </>;
}
export default SelectFooter;