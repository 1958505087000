import { observable } from "mobx";
import { isEmpty } from "../../../utils";
import BaseChannelSettings from "./BaseChannelSettings";

export default class InstagramChannelSettings extends BaseChannelSettings {
    @observable pageId: string;
    @observable pageName: string;
    @observable accountId: string;
    @observable accountUsername: string;
    @observable permissions: string[];

    constructor(settings?: Partial<InstagramChannelSettings>) {
        super();
        if (settings) this.update(settings);
    }

    hasPermissions(permissions: string[]) {
        if(!this.permissions) return false;
        return permissions.every(x => this.permissions.includes(x));
    }

    get isValid() {
        return !isEmpty(this.accessToken) && this.hasPage && this.hasAccount;
    }

    get hasPage() {
        return !isEmpty(this.pageId) && !isEmpty(this.pageName);
    }

    get hasAccount() {
        return !isEmpty(this.accountId) && !isEmpty(this.accountUsername);
    }

    update(entity: Partial<InstagramChannelSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    hasChanges(settings: InstagramChannelSettings) {
        return super.hasChanges(settings)
            || this.pageId !== settings.pageId
            || this.accountId !== settings.accountId;
    }

    clone() {
        return new InstagramChannelSettings(this);
    }
}