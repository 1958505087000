import { Updatable } from '../../..';
import { PaymentGatewayPaymentMethodGroupType, PaymentGatewayPaymentMethodType, PaymentGatewayPaymentObjectType, PaymentGatewayTaxType } from '../PaymentGatewayInfo';

export default class ProdamusSettings extends Updatable {
    public account: string;
    public secretKey: string;
    public isTest: boolean;
    public isWidgetModeEnabled: boolean;
    public tax: PaymentGatewayTaxType;
    public paymentMethod: PaymentGatewayPaymentMethodType;
    public paymentObject: PaymentGatewayPaymentObjectType;
    public paymentMethodGroups: PaymentGatewayPaymentMethodGroupType[];

    update(entity: Partial<ProdamusSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}