import { action, observable, computed } from 'mobx';
import { Admin, Contact, Entity, User, AccelFile, CourseItem, LibraryItem, SchoolContentSectionType } from '../..';

export enum CourseItemCommentState {
    Moderation = 'moderation',
    Published = 'published',
    NotPublished = 'notPublished'
}

export default class SchoolContentItemComment extends Entity {
    constructor(item?: Partial<SchoolContentItemComment>) {
        super(item);
        if (item) this.update(item);
    }
    @observable text: string;
    @observable state: CourseItemCommentState;
    @observable contact?: Contact;
    @observable admin?: Admin;
    @observable createdDate: Date;
    @observable updatedDate: Date;
    @observable parentId?: string;
    @observable replyId?: string;
    @observable children: SchoolContentItemComment[];
    @observable childrenCount: number;
    @observable rating: number;
    @observable files: AccelFile[];
    @observable reply: SchoolContentItemComment;
    @observable courseItem: CourseItem;
    @observable libraryItem: LibraryItem;
    @observable sectionType: SchoolContentSectionType;

    @computed get author(): User | undefined {
        return this.admin ?? this.contact;
    }

    @computed get isEmpty() {
        return !this.text && this.files.length == 0;
    }

    @computed get isRoot() {
        return this.parentId == null;
    }

    @computed get hasMore() {
        return this.childrenCount > this.children.length;
    }

    static fromJson(json: any): SchoolContentItemComment {
        return new SchoolContentItemComment({
            ...json,
            contact: json.student ? Contact.fromJson(json.student) : undefined,
            admin: json.admin ? Admin.fromJson(json.admin) : undefined,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            children: json.children ? json.children.map(SchoolContentItemComment.fromJson) : undefined,
            rating: json.progress ? json.progress.rating : undefined,
            files: json.files ? json.files.map(AccelFile.fromJson) : [],
            reply: json.reply ? SchoolContentItemComment.fromJson(json.reply) : undefined,
            courseItem: json.courseItem ? CourseItem.fromJson(json.courseItem) : undefined,
            libraryItem: json.libraryItem ? LibraryItem.fromJson(json.libraryItem) : undefined
        });
    }

    @action
    update(comment: Partial<SchoolContentItemComment>, allowUndefined = false) {
        super.update(comment, allowUndefined);
    }
}
