import React, { useMemo } from 'react';
import useFormRuleBuilder, { FormRuleBuilder } from '../hooks/useFormRuleBuilder/useFormRuleBuilder';
import { FormItemProps, Rule } from "antd/lib/form";
import { Checkbox, CheckboxProps, DatePicker, DatePickerProps, Input, InputNumber, InputNumberProps, InputProps, Radio, RadioGroupProps, Form, SwitchProps, Switch } from 'antd';
import { PasswordProps, TextAreaProps } from 'antd/lib/input';
import { combineClasses } from '../../utils';
import styles from './FormBuilder.module.scss';
import { LockOutlined } from '@ant-design/icons';

type Props = {
}
function FormBuilder(props: Props) {
    return <></>;
}


type FormBuilderItemProps = Omit<FormItemProps, 'rules'> & {
    disabled?: boolean;
    rules?: (rb: FormRuleBuilder) => FormRuleBuilder;
    /**
     * backward compability
     */
    _rules?: Rule[];
}
FormBuilder.Item = (props: FormBuilderItemProps) => {
    const ruleBuilder = useFormRuleBuilder();
    const rules = useMemo(() => {
        if (props._rules)
            return props._rules;
        return props.rules?.(ruleBuilder).build();
    }, [props._rules, props.rules]);

    return <Form.Item {...props} rules={rules} />
}

type FormBuilderItemNoChildrenProps = Omit<FormBuilderItemProps, 'children'>;

type FormBuilderInputItemProps = FormBuilderItemNoChildrenProps & {
    onChange?: InputProps['onChange'];
    itemProps?: InputProps
};

FormBuilder.Input = ({ itemProps, onChange, disabled, ...formItemProps }: FormBuilderInputItemProps) => {
    return <FormBuilder.Item {...formItemProps}>
        <Input {...itemProps} disabled={disabled || itemProps?.disabled} onChange={onChange ?? itemProps?.onChange} />
    </FormBuilder.Item>;
}

type FormBuilderInputPasswordProps = FormBuilderItemNoChildrenProps & {
    onChange?: PasswordProps['onChange'];
    itemProps?: PasswordProps
};

FormBuilder.InputPassword = ({ itemProps, onChange, disabled, ...formItemProps }: FormBuilderInputPasswordProps) => {
    return <FormBuilder.Item {...formItemProps}>
        <Input.Password {...itemProps} disabled={disabled || itemProps?.disabled} onChange={onChange ?? itemProps?.onChange} placeholder='Enter your password' prefix={<LockOutlined />} />
    </FormBuilder.Item>;
}

type FormBuilderTextAreaItemProps = FormBuilderItemNoChildrenProps & {
    itemProps?: TextAreaProps;
    placeholder?: TextAreaProps['placeholder'];
} & Pick<TextAreaProps, 'onChange' | 'autoSize'>;

FormBuilder.TextArea = ({ itemProps, autoSize, onChange, disabled, placeholder, ...formItemProps }: FormBuilderTextAreaItemProps) => {
    return <FormBuilder.Item {...formItemProps}>
        <Input.TextArea {...itemProps}
            autoSize={autoSize ?? itemProps?.autoSize}
            onChange={onChange ?? itemProps?.onChange}
            disabled={disabled ?? itemProps?.disabled}
            placeholder={placeholder ?? itemProps?.placeholder} />
    </FormBuilder.Item>;
}


type FormBuilderInputNumberProps = FormBuilderItemNoChildrenProps & {
    itemProps?: InputNumberProps;
    min?: number;
    max?: number;
} & Pick<InputNumberProps, 'onChange'>;

FormBuilder.InputNumber = ({ itemProps, onChange, min, max, disabled, ...formItemProps }: FormBuilderInputNumberProps) => {
    return <FormBuilder.Item {...formItemProps}>
        <InputNumber {...itemProps} disabled={disabled ?? itemProps?.disabled} min={min ?? itemProps?.min} max={max ?? itemProps?.max} onChange={onChange ?? itemProps?.onChange} decimalSeparator=',' />
    </FormBuilder.Item>;
}


type FormBuilderCheckboxProps = FormBuilderItemNoChildrenProps & {
    onChange?: CheckboxProps['onChange'];
    itemProps?: CheckboxProps
};

FormBuilder.Checkbox = ({ itemProps, onChange, label, disabled, ...formItemProps }: FormBuilderCheckboxProps) => {
    return <FormBuilder.Item {...formItemProps} valuePropName='checked'>
        <Checkbox {...itemProps} disabled={disabled ?? itemProps?.disabled} onChange={onChange ?? itemProps?.onChange}>
            {label}
        </Checkbox>
    </FormBuilder.Item>;
}


type FormBuilderSwitchProps = FormBuilderItemNoChildrenProps & {
    onChange?: SwitchProps['onChange'];
    itemProps?: SwitchProps;
};
FormBuilder.Switch = ({ itemProps, onChange, label, disabled, ...formItemProps }: FormBuilderSwitchProps) => {
    return <FormBuilder.Item {...formItemProps} valuePropName='checked'>
        <Switch {...itemProps} disabled={disabled ?? itemProps?.disabled} onChange={onChange ?? itemProps?.onChange} />
        {label !== undefined && <span className='ml-5'>{label}</span>}
    </FormBuilder.Item>;
}


type FormBuilderRadioProps = Omit<FormBuilderItemNoChildrenProps, 'options'> & {
    onChange?: RadioGroupProps['onChange'];
    itemProps?: RadioGroupProps;
    options: RadioGroupProps['options'];
};

FormBuilder.Radio = ({ itemProps, onChange, options, disabled, ...formItemProps }: FormBuilderRadioProps) => {
    return <FormBuilder.Item {...formItemProps} >
        <Radio.Group {...itemProps} options={options ?? itemProps?.options}
            disabled={disabled ?? itemProps?.disabled}
            optionType={itemProps?.optionType ?? 'button'}
            buttonStyle={itemProps?.buttonStyle ?? 'solid'}
            onChange={onChange ?? itemProps?.onChange} />
    </FormBuilder.Item>;
}

type FormBuilderCustomProps = FormBuilderItemProps & {
};

FormBuilder.Custom = (props: FormBuilderCustomProps) => {
    return <FormBuilder.Item {...props}>
        {props.children}
    </FormBuilder.Item>;
}

type FormBuilderDatePickerProps = FormBuilderItemNoChildrenProps & {
    onChange?: DatePickerProps['onChange'];
    itemProps?: DatePickerProps
};

FormBuilder.DatePicker = ({ itemProps, onChange, disabled, ...formItemProps }: FormBuilderDatePickerProps) => {
    return <FormBuilder.Item {...formItemProps}>
        <DatePicker {...itemProps} disabled={disabled ?? itemProps?.disabled} onChange={onChange ?? itemProps?.onChange} />
    </FormBuilder.Item>;
}


type FormBuilderSectionProps = FormBuilderItemProps;
FormBuilder.Section = ({ label, ...props }: FormBuilderSectionProps) => {
    return <FormBuilder.Item {...props} label={<div className='fw-500 fs-16'>{label}</div>} className={combineClasses(styles.form_section, props.className ?? 'mb-30')}>
        {props.children}
    </FormBuilder.Item>;
}


type FormBuilderStaticProps = FormBuilderItemProps & {
    content?: React.ReactNode;
    placeholder?: React.ReactNode;
    children?: React.ReactNode;
}
FormBuilder.Static = ({ label, content, placeholder, ...props }: FormBuilderStaticProps) => {
    return <FormBuilder.Item {...props} label={label}>
        {props.children ?? content ?? placeholder}
    </FormBuilder.Item>;
}

export default FormBuilder;