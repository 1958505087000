import React, { useState, useEffect, useContext } from "react";
import RateStep from './RateStep';
import styles from './Rate.module.scss';
import { Context } from '../AccelProvider/AccelProvider';
import { observer } from 'mobx-react';

type RateProps = {
    defaultRating?: number;
    rating?: number;
    disabled?: boolean;
    /**
     * @deprecated use onChange instead
     * @param value 
     * @returns 
     */
    onClick?: (value: number) => void;
    onChange?: (value: number) => void;
    size?: 'default' | 'small';
}

const Rate: React.FC<RateProps> = ({ defaultRating, rating, disabled, size, onClick, onChange }) => {
    const ctx = useContext(Context);
    const deviceSize = ctx.deviceStore;

    const getSteps = (): Array<number> => {
        let i = 1;
        return Array.from(Array(10), () => i++);
    }

    const [steps] = useState(getSteps());
    const [_rating, setRating] = useState(defaultRating ?? rating);

    useEffect(() => {
        setRating(rating);
    }, [rating]);

    return <div className={styles.rate} data-disabled={disabled} data-size={size}>
        {steps.map(x =>
            <RateStep key={x}
                value={x}
                text={x}
                selected={_rating == x}
                breakLine={x == 5 && deviceSize.size == 'mobile'}
                onClick={() => { onClick?.(x); onChange?.(x); }} />)}
    </div>;
}
Rate.defaultProps = {
    size: 'default',
    disabled: false
}
export default observer(Rate);