import { action, observable } from "mobx";
import moment, { type Moment } from "moment";
import { Entity, Scenario, PipelineStage, PipelineField } from "..";


export declare type PipelineType = 'crm';

export default class Pipeline extends Entity {
    constructor(p?: Partial<Pipeline>) {
        super();
        if (p) this.update(p);
    }

    @observable name: string;
    @observable description: string;
    type: PipelineType;
    @observable autocompleteDealAfterCompleteOrders: boolean;

    @observable stages: PipelineStage[];

    createdDate: Moment;
    updatedDate?: Moment;

    @observable fields?: PipelineField[];
    @observable scenario: Scenario;

    @observable stageCount: number;
    @observable itemCount: number;

    static fromJson(json: any) {
        const pipeline = new Pipeline({
            id: json.id,
            type: json.type,
            name: json.name,
            description: json.description,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined,
            fields: json.field,
            stages: json.stages ? json.stages.map(PipelineStage.fromJson) : undefined,
            stageCount: json.stageCount,
            itemCount: json.itemCount,
            autocompleteDealAfterCompleteOrders: json.autocompleteDealAfterCompleteOrders,
        });

        if (pipeline.scenario)
            pipeline.scenario.update({ pipeline });

        return pipeline;
    }

    @action
    update(session: Partial<Pipeline>) {
        super.update(session);
    }
}