import { AccelFile, Admin, Entity, Video } from '../..';
import { observable, action } from 'mobx';
import { TimeSpan } from '../../../utils';

export enum VideoStreamState {
    CloudUploading = 'cloudUploading',
    CloudUploaded = 'cloudUploaded',
    ProviderUploading = 'providerUploading',
    ProviderUploaded = 'providerUploaded',
    Downloading = 'downloading',
    PrepareConverting = 'prepareConverting',
    Converting = 'converting',
    Completed = 'completed',
    Failure = 'failure',
    Aborted = 'aborted',
    DeleteFailure = 'deleteFailure',
    Deleting = 'deleting'
}

export enum VideoStreamSubState {
    Default = 'default',
    Reconvert = 'reconvert',
    Reconverting = 'reconverting',
    Completed = 'completed',
    Failure = 'failure'
}

export declare type VideoStreamAsset = {
    id: string,
    quality: string,
    resolution: string,
    fileSize: number,
    fileType: string,
    originalName: string,
    downloadLink: string
}

export default class VideoStream extends Entity {
    constructor(videoStream?: Partial<VideoStream>) {
        super(videoStream);
        if (videoStream) this.update(videoStream);
    }
    @observable file: AccelFile;
    @observable video: Video;

    @observable duration: TimeSpan;
    @observable originalSize: number;
    @observable progress: number;
    @observable width: number;
    @observable height: number;

    @observable mp4: string;
    @observable dash: string;
    @observable hlsfmp4: string;
    @observable hls: string;
    @observable providerId: string;

    @observable state: VideoStreamState;
    @observable subState: VideoStreamSubState;
    @observable errorMessage: string;
    @observable createdDate: Date;
    @observable storyboard: AccelFile;
    @observable createdByAdmin: Admin;
    @observable images: AccelFile[];

    get isUploading() {
        return this.state == VideoStreamState.CloudUploading;
    }

    get isInProgress() {
        return this.state == VideoStreamState.CloudUploading
            || this.state == VideoStreamState.CloudUploaded
            || this.state == VideoStreamState.Downloading
            || this.state == VideoStreamState.ProviderUploading
            || this.state == VideoStreamState.ProviderUploaded
            || this.state == VideoStreamState.Converting;
    }

    get hasError() {
        return this.state == VideoStreamState.Failure
            || this.state == VideoStreamState.Aborted;
    }

    get isCompleted() {
        return this.state == VideoStreamState.Completed;
    }

    static fromJson(json: any): VideoStream {
        return new VideoStream({
            ...json,
            duration: json.duration ? TimeSpan.fromSeconds(json.duration) : undefined,
            file: json.file ? AccelFile.fromJson(json.file) : undefined,
            storyboard: json.storyboard ? AccelFile.fromJson(json.storyboard) : undefined,
            video: json.video ? Video.fromJson(json.video) : undefined,
            createdByAdmin: json.createdByAdmin ? Admin.fromJson(json.createdByAdmin) : undefined,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            images: json.images ? json.images.map((x: any) => AccelFile.fromJson(x.file)) : [],
        });
    }

    @action
    update(videoStream: Partial<VideoStream>, allowUndefined = false) {
        super.update(videoStream, allowUndefined);
    }
}
