import { RefSelectProps, Select } from "antd";
import { observer } from "mobx-react";
import { useCallback, useEffect, useRef, useState } from "react";
import SelectFooter from "../components/SelectFooter/SelectFooter";
import useSelect, { BaseSelectProps } from "../components/SelectBase/SelectBase";
import styles from '../Select.module.scss';
import { BaseFilter, Entity } from "../../../models";
import { ListStore } from "../../../stores";
import useDebouncedCallback from "../../hooks/useDebouncedCallback";
import { TimeSpan, combineClasses } from "../../../utils";
import React from "react";

// type to add custom methods to the select ref
export type RefSelectAsyncApi = RefSelectProps & {
    clearCache: () => void;
    update: () => Promise<void>;
}

type PropsToOmit = 'options' | 'labelInValue' | 'dropdownRender' | 'loading';
export type SelectAsyncProps<TEntity extends Entity, TFilter extends BaseFilter<TEntity>> = Omit<BaseSelectProps<TEntity>, PropsToOmit>
    & {
        selectRef?: React.Ref<RefSelectAsyncApi | null>;
        store: ListStore<TEntity, TFilter>
        initialIds?: string | string[];
        fields?: string;
        customOptions?: TEntity[];
        onInited?: (items: TEntity[]) => void;
        onInitedSingle?: (item: TEntity) => void;
    };

// используем эти пропсы при реализации селекта
export type SelectAsyncExternalProps<TEntity extends Entity, TFilter extends BaseFilter<TEntity>> = Omit<SelectAsyncProps<TEntity, TFilter>, 'itemLabel' | 'itemLabelValue' | 'store'>;

const useInitializer = function <TEntity extends Entity, TFilter extends BaseFilter<TEntity>>
    ({ store, initialIds, fields, onInited, onInitedSingle }: SelectAsyncProps<TEntity, TFilter>) {

    const [idsToInit] = useState<string[]>(() => {
        if (!initialIds) return [];
        return Array.isArray(initialIds) ? initialIds : [initialIds];
    });
    const [inited, setInited] = useState(idsToInit.length == 0);

    const init = useCallback(async () => {
        const initialFilter = new BaseFilter({
            ids: idsToInit,
            softDeleted: null,
            useBaseFilter: false
        }) as TFilter;

        // ! make sure that the store implements `fetchItems` properly using custom filter
        const result = await store.fetchItems(fields, initialFilter);

        if (!result.success) {
            console.error('[SelectAsync] Fetching items failed', result);
            // set inited anyway
            setInited(true);
            return;
        };

        const items = result.response.items;
        onInited?.(items);
        if (items.length > 0)
            onInitedSingle?.(items[0]);

        setInited(true);
    }, []);

    useEffect(() => {
        if (!inited)
            init();
    }, []);

    return inited;
}


function SelectAsync<TEntity extends Entity, TFilter extends BaseFilter<TEntity>>(props: SelectAsyncProps<TEntity, TFilter>) {

    const { selectRef, store, inline, itemLabel, itemLabelValue, value: _value, defaultValue, initialIds, fields, customOptions = [], menuFooter, onChange, onChangeSingle, onInited, onInitedSingle, ...selectProps } = props;

    const alreadyFetched = useRef<boolean>(false);

    const inited = useInitializer(props);

    const { mode, value, options, handleChange } = useSelect(props,
        customOptions.length > 0 ? [...store.items, ...customOptions] : store.items,
        x => props.itemKey?.(x) ?? x.id);

    const handleSearch = useDebouncedCallback(async (search: string) => {
        store.updateFilter({ search });
        await store.fetch(fields);
    }, [], TimeSpan.fromMilliseconds(300));

    const handleDropdownVisibleChange = useCallback(async (visible: boolean) => {
        if (alreadyFetched.current || !visible)
            return;

        alreadyFetched.current = true;
        await store.fetch(fields);
    }, []);

    // handle custom methods passed through ref
    const handleRef = useCallback((ref: RefSelectProps) => {
        if (!selectRef || !ref) return;
        const r = {
            ...ref,
            clearCache: () => {
                alreadyFetched.current = false;
                store.reset();
            },
            update: async () => {
                await store.fetch(fields)
            }
        } as RefSelectAsyncApi;
        // in case React.RefCallback
        if (typeof selectRef === 'function') {
            selectRef(r);
        } else {
            //@ts-ignore
            selectRef.current = r;
        }
    }, []);

    useEffect(() => {
        // trigger open by default
        if (selectProps.open === true)
            handleDropdownVisibleChange(true);
    }, []);

    return <Select
        {...selectProps}
        ref={handleRef}
        mode={mode}
        value={value}
        options={options}
        onChange={handleChange}
        disabled={!inited || props.disabled}
        onSearch={(x) => {
            handleSearch(x);
            selectProps.onSearch?.(x);
        }}
        onDropdownVisibleChange={open => {
            handleDropdownVisibleChange(open);
            selectProps.onDropdownVisibleChange?.(open);
        }}
        dropdownRender={menuFooter
            ? menu => <SelectFooter footer={menuFooter} children={menu} />
            : undefined}
        loading={props.store.fetching}
        labelInValue
        // превент поиска по инпуту + отслеживает изменения options
        filterOption={false}
        bordered={!inline}
        showAction={['focus', 'click']}
        className={combineClasses(styles.accel_ant_select, styles[`accel_ant_select_size_${props.size}`], props.className)}
    />;
}

SelectAsync.useRef = () => {
    const ref = useRef<RefSelectAsyncApi | null>(null);
    return ref;
}

(SelectAsync as React.FC<SelectAsyncProps<any, any>>).defaultProps = {
    showArrow: true,
    showSearch: true
}
export default observer(SelectAsync);




