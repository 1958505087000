import { isEmpty } from 'lodash';
import { action, computed, observable } from 'mobx';
import moment, { type Moment } from 'moment';
import {
    ChatBot,
    ChatChannelType,
    Entity, ExtendedFilter, Glossary, Scenario, School, SiteDomain,
    WebinarContent, WebinarIntegrationSettings,
    WebinarRegistrationSettings, WebinarRoomSettings
} from '..';
import { TimeSpan } from '../../utils';
import Timezone from '../timezone/timezone';
import WebinarDesignSettings from './Settings/WebinarDesignSettings';

export enum WebinarStatus {
    NotStarted = 'notStarted',
    InProgress = 'inProgress',
    Completed = 'completed'
}

export enum WebinarType {
    Basic = 'basic',
    Template = 'template',
    Auto = 'auto'
}

export type ChatBotData = {
    type: ChatChannelType;
    botNumber: number;
    channelNumber: number;
    channelName: string;
}

export default class Webinar extends Entity {
    constructor(webinar?: Partial<Webinar>) {
        super(webinar);
        if (webinar) this.update(webinar);
    }

    @observable name: string;
    @observable internalName: string;
    @observable shortName: string | null;
    @observable number: number;
    @observable description: string;
    @observable comment: string;
    @observable afterword: string;
    @observable school: School;
    @observable template: Webinar;
    @observable source: Webinar;
    @observable isTemplate: boolean;
    @observable useActiveAsNearest: boolean;

    @observable afterCompletionRedirect: boolean;
    @observable afterCompletionRedirectUrl: string | null;

    /**
     * Scheduled date in user local timezone
     */
    @observable scheduledDate: Moment;
    @observable scheduledTimezone: Timezone;

    @observable scheduleCron: string | null;
    @observable scheduleCronTimezone: Timezone | null;

    /**
     * Expected duration
     */
    @observable duration: TimeSpan | null;
    @observable currentDuration: TimeSpan;

    @observable startedDate?: Moment;
    @observable precompletedDate?: Moment;
    @observable completedDate?: Moment;
    @computed get resumeUntilDate(): Moment | null {
        if (!this.precompletedDate) return null;
        return this.precompletedDate.add(15, 'minutes');
    }
    @computed get status(): WebinarStatus {
        if (this.precompletedDate)
            return WebinarStatus.Completed;
        if (this.startedDate)
            return WebinarStatus.InProgress;
        return WebinarStatus.NotStarted;
    }

    // @observable streamSources: string[];
    @observable blockedIPs: string[];
    @observable chatChannels: string[];
    @observable userCount: number;
    @observable scenario: Scenario;
    @observable siteDomain: SiteDomain | null;

    @observable createdDate: Moment;
    @observable updatedDate?: Moment;

    @observable registrationSettings: WebinarRegistrationSettings;
    @observable designSettings: WebinarDesignSettings;
    @observable roomSettings: WebinarRoomSettings;
    @observable integrationSettings: WebinarIntegrationSettings;

    @observable contents: WebinarContent[];
    @observable chatBot: ChatBot;
    // webinar data model
    @observable chatBots: ChatBotData[];
    @observable glossaries: Glossary[];

    @observable useContactFilter: boolean;
    @observable contactFilter: ExtendedFilter;

    get startingIn(): TimeSpan {
        if (this.status != WebinarStatus.NotStarted)
            return TimeSpan.zero;
        let diffMs = 0;
        if (this.scheduledDate.isAfter())
            diffMs = this.scheduledDate.diff(moment(), 'milliseconds');
        else diffMs = moment().diff(this.scheduledDate, 'milliseconds');
        return TimeSpan.fromMilliseconds(diffMs);
    }

    get startedAgo(): TimeSpan {
        if (!this.startedDate)
            return TimeSpan.zero;
        const diffMs = moment().diff(this.startedDate, 'milliseconds');
        return TimeSpan.fromMilliseconds(diffMs);
    }
    // TODO use tz instead
    @computed get scheduledDateUtc() {
        return moment(this.scheduledDate).utc();
    }

    // TODOuse tz instead
    @computed get scheduledDateByTZ() {
        return moment(this.scheduledDateUtc).add(this.scheduledTimezone.offset.asMinutes(), 'm');
    }

    // TODO use tz instead
    @computed get localScheduledDate() {
        return moment(this.scheduledDateUtc).add(moment().utcOffset(), 'm');
    }

    @computed get timeLeftBeforeStart() {
        return TimeSpan.fromMilliseconds(moment(this.scheduledDate).diff(moment()).valueOf());
    }


    @computed get cuepoints() {
        return Webinar.generateCuepoints(this.currentDuration);
    }

    @computed get isAuto() {
        return this.template != null;
    }

    @computed get usesTemplateScenario() {
        return this.isAuto && !this.hasOwnScenario;
    }

    /**
     * Template scenario or it's own scenario
     */
    @computed get hasActiveScenario() {
        if (this.usesTemplateScenario)
            return this.template?.scenario?.isActive ?? false;
        return this.scenario?.isActive ?? false;
    }

    @computed get hasOwnScenario() {
        return this.scenario != null;
    }

    @computed get hasSchedule() {
        return this.scheduleCron != null;
    }

    @computed get hasComment() {
        return !isEmpty(this.comment);
    }

    @computed get type(): WebinarType {
        if (this.isTemplate)
            return WebinarType.Template;

        if (this.template != null)
            return WebinarType.Auto;

        return WebinarType.Basic;
    }

    @computed get canRedirectAfterCompletion() {
        return this.afterCompletionRedirect === true && !isEmpty(this.afterCompletionRedirectUrl);
    }

    @computed get hasInternalName() {
        return !isEmpty(this.internalName);
    }

    static fromJson(json: any): Webinar {
        const webinar = new Webinar({
            id: json.id,
            name: json.name,
            internalName: json.internalName,
            softDeleted: json.softDeleted,
            shortName: json.shortName,
            description: json.description,
            comment: json.comment,
            afterword: json.afterword,
            userCount: json.userCount,
            number: json.number,
            isTemplate: json.isTemplate,
            useActiveAsNearest: json.useActiveAsNearest,
            afterCompletionRedirect: json.afterCompletionRedirect,
            afterCompletionRedirectUrl: json.afterCompletionRedirectUrl,
            // streamSources: json.streamSourcesJson ? JSON.parse(json.streamSourcesJson) : [],
            blockedIPs: json.blockedIPsJson ? JSON.parse(json.blockedIPsJson) : [],
            chatChannels: json.chatChannelsJson ? JSON.parse(json.chatChannelsJson) : [],
            // TODO need to convert scheduledDate to selected timezone by default
            scheduledDate: json.scheduledDate ? moment(json.scheduledDate) : undefined,
            scheduledTimezone: json.scheduledTimezone
                ? Timezone.fromJson(json.scheduledTimezone)
                : Timezone.utc(),

            scheduleCron: json.scheduleCron ? json.scheduleCron : undefined,
            scheduleCronTimezone: json.scheduleTimezone
                ? Timezone.fromJson(json.scheduleTimezone)
                : null,

            duration: json.duration ? TimeSpan.fromTicks(json.duration) : undefined,
            currentDuration: json.currentDurationMs ? TimeSpan.fromMs(json.currentDurationMs) : TimeSpan.zero,

            startedDate: json.startedDate ? moment(json.startedDate) : undefined,
            completedDate: json.completedDate ? moment(json.completedDate) : undefined,
            precompletedDate: json.precompletedDate ? moment(json.precompletedDate) : undefined,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined,
            template: json.template ? Webinar.fromJson(json.template) : undefined,
            source: json.source ? Webinar.fromJson(json.source) : undefined,

            registrationSettings: json.registrationSettingsJson
                ? WebinarRegistrationSettings.fromJson(JSON.parse(json.registrationSettingsJson))
                : undefined,
            designSettings: json.designSettingsJson ? WebinarDesignSettings.fromJson(JSON.parse(json.designSettingsJson)) : undefined,
            roomSettings: json.roomSettingsJson ? WebinarRoomSettings.fromJson(JSON.parse(json.roomSettingsJson)) : undefined,
            integrationSettings: json.integrationSettingsJson ? WebinarIntegrationSettings.fromJson(JSON.parse(json.integrationSettingsJson)) : undefined,

            school: json.school ? School.fromJson(json.school) : undefined,
            siteDomain: json.siteDomain ? SiteDomain.fromJson(json.siteDomain) : null,

            contents: json.contents ? json.contents.map(WebinarContent.fromJson) : undefined,
            chatBot: json.chatBot ? ChatBot.fromJson(json.chatBot) : undefined,
            chatBots: json.chatBots,
            glossaries: json.glossaryLinks ? json.glossaryLinks.map((x: any) => Glossary.fromJson(x.glossary)) : undefined,

            useContactFilter: json.useContactFilter,
            contactFilter: new ExtendedFilter({ filterJson: json.contactFilterJson })
        });
        if (webinar.scenario) webinar.scenario.update({ webinar });
        return webinar;
    }

    static get cuepointPeriod() {
        return TimeSpan.fromSeconds(30);
    }

    static generateCuepoints(duration: TimeSpan) {
        const cuepoints: number[] = [];
        let currentOffset = 0;
        while (currentOffset <= duration.seconds) {
            cuepoints.push(currentOffset);
            currentOffset += Webinar.cuepointPeriod.seconds;
        }
        return cuepoints;
    }

    clone(changes?: Partial<Webinar>): Webinar {
        return new Webinar({
            id: this.id,
            name: this.name,
            internalName: this.internalName,
            number: this.number,
            shortName: this.shortName,
            description: this.description,
            comment: this.comment,
            afterword: this.afterword,
            scheduledDate: this.scheduledDate.clone(),
            scheduledTimezone: this.scheduledTimezone?.clone(),
            scheduleCron: this.scheduleCron,
            scheduleCronTimezone: this.scheduleCronTimezone?.clone(),
            duration: this.duration,
            blockedIPs: this.blockedIPs,
            siteDomain: this.siteDomain,
            isTemplate: this.isTemplate,
            afterCompletionRedirect: this.afterCompletionRedirect,
            afterCompletionRedirectUrl: this.afterCompletionRedirectUrl,
            useActiveAsNearest: this.useActiveAsNearest,
            contents: this.contents?.slice(),
            ...changes
        });
    }

    hasChanges(webinar: Webinar) {
        return this.name != webinar.name
            || this.internalName != webinar.internalName
            || this.description != webinar.description
            || this.comment != webinar.comment
            || this.afterword != webinar.afterword
            // important to compare iso strings because moment is not equal due to tz
            || this.scheduledDate.toISOString() != webinar.scheduledDate.toISOString()
            || this.scheduledTimezone.id != webinar.scheduledTimezone.id
            || this.duration != webinar.duration
            || !this.blockedIPs.isEquals(webinar.blockedIPs)
            || this.shortName != webinar.shortName
            || this.siteDomain?.id != webinar.siteDomain?.id
            || this.afterCompletionRedirect != webinar.afterCompletionRedirect
            || this.afterCompletionRedirectUrl != webinar.afterCompletionRedirectUrl
            || this.useActiveAsNearest != webinar.useActiveAsNearest;
    }

    @action
    update(webinar: Partial<Webinar>, allowUndefined: boolean = false) {
        super.update(webinar, allowUndefined);
    }
}
